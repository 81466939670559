import React from "react";
import HalfFooter from "./half-footer";

const Footer = () => {
  return (
    <footer className="footer main-footer">
      <div className="footer-container text-center">
        <h2 className="footer-title">Title Registry</h2>
        <div className="footer-links">
          <a href="#">Project</a>
          <a href="#">Donate</a>
          <a href="#">About Us/Contact</a>
          <a href="#">Privacy Policy</a>
        </div>
      </div>

      <HalfFooter />
    </footer>
  );
};

export default Footer;
