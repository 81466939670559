import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateComponent = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const admin = JSON.parse(localStorage.getItem("admin"));
  const guest = JSON.parse(localStorage.getItem("guest"));
  if (user || admin || guest) {
    return <Outlet />;
  } else {
    localStorage.setItem("redirected_url", window.location.pathname);
    return <Navigate to="/login" />;
  }
};

export default PrivateComponent;
