import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function VerificationForm() {
  const { verificationToken } = useParams();
  const [successDiv, setSuccessDiv] = useState(false);
  const [alertDiv, setAlertDiv] = useState(false);
  const isRequestSent = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isRequestSent.current) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${serverBaseUrl}/user/verify-email`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "verification-token": verificationToken,
            },
          });
          if (response.status === 200) {
            setSuccessDiv(true);
          } else if (response.status === 401) {
            setAlertDiv(true);
          }
        } catch (error) {
          setAlertDiv(true);
        }
      };
      fetchData();
      isRequestSent.current = true;
    }
  }, [verificationToken]);

  const login = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <>
      {successDiv && (
        <div className="main-verify-div py-5">
          <h1>Email Verified Successfully</h1>
          <div className="checkmark my-5">&#10003;</div>
          <p>
            Go to{" "}
            <Link
              onClick={(e) => login(e)}
              style={{
                color: "#146c43",
                textDecoration: "none",
              }}
            >
              login
            </Link>
          </p>
        </div>
      )}
      {alertDiv && (
        <div className="main-verify-div py-5">
          <h1>Invalid verification link or expired</h1>
          <div className="checkmark crossmark my-5">&#10006;</div>
          <p>
            Go to{" "}
            <Link
              onClick={(e) => login(e)}
              style={{
                color: "#bb2d3b",
                textDecoration: "none",
              }}
            >
              login
            </Link>
          </p>
        </div>
      )}
    </>
  );
}

export default VerificationForm;
