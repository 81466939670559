import React, { useState } from "react";
import { DropdownToggle, Dropdown } from "reactstrap";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoWhite } from "../assets/images/logos/xtremelogowhite.svg";
import user1 from "../assets/images/user-1.png";
import "../assets/css/header.css";

const Header = ({ isAdmin }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const navigate = useNavigate();

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const logout = () => {
    localStorage.clear();
    isAdmin ? navigate("/admin") : navigate("/login");
  };

  const goToProfile = () => {
    isAdmin ? navigate("/admin/profile") : navigate("/user/profile");
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <span onClick={goToHome}>
          <LogoWhite />
        </span>
        <button className="navbar-toggler" onClick={showMobilemenu}>
          <FaBars color="#ffffff" size={14} />
        </button>
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown">
        <DropdownToggle color="primary" className="dropdown-toggler">
          <img
            src={user1}
            alt="profile"
            className="rounded-circle"
            width="30"
          ></img>
        </DropdownToggle>
        <div className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
          <div className="dropdown-item" onClick={goToProfile}>
            Edit Profile
          </div>
          <div className="dropdown-item" onClick={logout}>
            Logout
          </div>
        </div>
      </Dropdown>
    </nav>
  );
};

export default Header;
