import React from "react";
import TopBar from "../component/topbar.js";
import HalfFooter from "../component/half-footer.js";
import boardSvg from "../assets/vectors/img-7.svg";
import "../assets/css/singleForm.css";

const SingleForm = () => {
  return (
    <div className="page single-form-page">
      <TopBar />
      <div className="page-main-div align-items-start justify-content-start">
        <div className="container">
          <div className="card">
            <div className="icon-container">
            <img
                src={boardSvg}
                alt="Board"
                style={{ width: "34px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Screenplay, Synopsis, Treatments, etc</h3>
            </div>
          </div>
        </div>
      </div>
      <HalfFooter />
    </div>
  );
};

export default SingleForm;
