import React from "react";
import Header from "../component/header.js";
import UserTable from "../component/userTable.js";
import UserSidebar from "../component/userSidebar.js";
import "../assets/css/dashboard.css";

function UserRegistries() {
  return (
    <div className="dashboard">
      <UserSidebar />
      <div className="main-dashboard">
        <div className="header-div">
          <Header isAdmin={false} />
        </div>
        <div className="table-div">
          <UserTable />
        </div>
      </div>
    </div>
  );
}

export default UserRegistries;
