import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { PiCertificateThin } from "react-icons/pi";
import { ReactComponent as LogoDark } from "../assets/images/logos/xtremelogo.svg";
import "../assets/css/sidebar.css";

const Sidebar = () => {
  const navigate = useNavigate();
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const location = useLocation();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div id="sidebarArea" className="sidebar">
      <div className="sidebar-header">
        <span onClick={goToHome} style={{ cursor: "pointer" }}>
          <LogoDark />
        </span>
        <button className="close-btn" onClick={showMobilemenu}>
          ×
        </button>
      </div>
      <div className="sidebar-nav">
        <ul>
          <li className="nav-item">
            <Link
              to="/admin/dashboard"
              className={
                location.pathname === "/admin/dashboard"
                  ? "nav-link nav-link-active"
                  : "nav-link"
              }
            >
              <FaRegUser />
              <span>Users</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/admin/registries"
              className={
                location.pathname === "/admin/registries"
                  ? "nav-link nav-link-active"
                  : "nav-link"
              }
            >
              <FaRegCheckCircle />
              <span>Registries</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
