import React from "react";
import illustration from "../assets/vectors/img-1.svg";
import dotImg from "../assets/vectors/img-2.svg";

function LeftComponent() {
  return (
    <div className="left-component">
      <h3>Welcome to Title Registry</h3>
      <p>
        Register and store your public or private Digital Document files with
        Title Registry. The best way to keep up with your most important files.
      </p>
      <img src={illustration} alt="tr-illustration" className="illustration" />
      <img src={dotImg} alt="dot-illustration" className="dot-img" />
      <p className="reserved-rights">All Rights Reserved to Title Registry</p>
    </div>
  );
}

export default LeftComponent;
