import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { TfiUpload } from "react-icons/tfi";
import TopBar from "../component/topbar.js";
import "../assets/css/addDocument.css";

function AddDocumentForm() {
  const [documentType, setDocumentType] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleDocumentDescriptionChange = (event) => {
    setDocumentDescription(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(selectedFile, documentType, documentDescription);
  };

  return (
    <div className="add-document-page page">
      <TopBar />
      <div className="page-main-div">
        <div className="add-document-form">
          <h2>Add Documents to this title</h2>
          <p>Please Upload Document</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="document">
                Document <span className="required">*</span>
              </label>
              <div className="file-upload-container">
                <input
                  type="file"
                  id="document"
                  name="document"
                  className="file-input"
                  onChange={handleFileChange}
                  required
                  ref={fileInputRef}
                />
                <button
                  className="button-text"
                  type="button"
                  onClick={handleButtonClick}
                >
                  {selectedFile ? selectedFile.name : "Choose File"}
                </button>
                <span>
                  <TfiUpload />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="documentType">
                Document Type<span className="required">*</span>
              </label>
              <select
                id="documentType"
                name="documentType"
                className="select"
                value={documentType}
                onChange={handleDocumentTypeChange}
                required
              >
                <option value="">Select Type here</option>
                <option value="type1">Type 1</option>
                <option value="type2">Type 2</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="documentDescription">Document Description</label>
              <textarea
                id="documentDescription"
                placeholder="Add Text here"
                rows={5}
                name="documentDescription"
                value={documentDescription}
                onChange={handleDocumentDescriptionChange}
              />
            </div>
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
          <div className="go-back">
            <span className="arrow-span">
              <IoMdArrowBack size={20} />
            </span>
            <Link to="/" className="back-button">
              Go Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDocumentForm;
