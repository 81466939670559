import React from "react";
import { Link } from "react-router-dom";
import TopBar from "../component/topbar";
import Footer from "../component/footer";
import cardOne from "../assets/vectors/img-4.svg";
import cardTwo from "../assets/vectors/img-5.svg";
import cardThree from "../assets/vectors/img-6.svg";
import "../assets/css/home.css";

const Home = () => {
  return (
    <div className="page home-page">
      <TopBar />
      <div className="page-main-div">
        <div className="page-container">
          <div className="card">
            <div className="card-content">
              <div className="card-image">
                <img src={cardOne} alt="card-one" />
              </div>
              <div className="card-text">
                <h2>Title Registry</h2>
                <ul>
                  <li>Document and Digital File Registration and Storage.</li>
                  <li>
                    Content Creators – Writers, Artists, Photographers,
                    Engineers, Architect, Students, Scientist
                  </li>
                  <li>Registering your titles should not be Expensive</li>
                </ul>
                <Link to="/registry" className="text-decoration-none">
                  <button className="border-0 text-white">Register</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-image">
                <img src={cardTwo} alt="card-two" />
              </div>
              <div className="card-text card-two-text">
                <h2>SECURE. FAST. RELIABLE.</h2>
                <p>Free Storage for life</p>
                <p>Verifiable Authentication</p>
                <p>Searchable Database</p>
                <p>Free Account</p>
                <Link to="/login" className="text-decoration-none">
                  <button className="border-0 text-white">
                    Create Account
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-image">
                <img src={cardThree} alt="card-three" />
              </div>
              <div className="card-text">
                <h2>Democratizing Content Registration</h2>
                <p>
                  Writers, artists, photographers, engineers, architects,
                  students scientists, beneficiaries and content creators
                  seeking a reputable title registration firm, go no further
                  because our company, Title Registry, has your back.
                </p>
                <Link to="/" className="text-decoration-none">
                  <button className="text-white border-0">Subscribe</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
