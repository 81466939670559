import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AdminRoute = () => {
  const admin = JSON.parse(localStorage.getItem("admin"));
  return admin ? <Outlet /> : <Navigate to="/login" />;
};

export default AdminRoute;

export const GuestRoute = () => {
  const guest = JSON.parse(localStorage.getItem("guest"));
  const user = JSON.parse(localStorage.getItem("user"));
  return guest || user ? <Outlet /> : <Navigate to="/login" />;
};

export const UserRoute = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? <Outlet /> : <Navigate to="/login" />;
};
