export const intendedMediums = [
  { value: "Interactive", label: "Interactive" },
  { value: "Literary", label: "Literary" },
  { value: "Music", label: "Music" },
  { value: "Online/digital", label: "Online/digital" },
  { value: "Radio", label: "Radio" },
  { value: "Screen", label: "Screen" },
  { value: "Stage", label: "Stage" },
  { value: "Tv", label: "Tv" },
  { value: "Unspecified", label: "Unspecified" },
  { value: "V/c", label: "V/c" },
  { value: "Videogame", label: "Videogame" },
];

export const materialOptions = [
  { value: "Animation", label: "Animation" },
  { value: "Art", label: "Art" },
  { value: "Autobiography", label: "Autobiography" },
  { value: "Bible", label: "Bible" },
  { value: "Bio", label: "Bio" },
  { value: "Book", label: "Book" },
  { value: "Commercial", label: "Commercial" },
  { value: "Concept", label: "Concept" },
  { value: "Documentry", label: "Documentry" },
  { value: "Format", label: "Format" },
  { value: "Gameshow", label: "Gameshow" },
  { value: "Ipr use only", label: "Ipr use only" },
  { value: "Lyrics", label: "Lyrics" },
  { value: "Menuscript", label: "Menuscript" },
  { value: "Music", label: "Music" },
  { value: "Musical", label: "Musical" },
  { value: "Notes", label: "Notes" },
  { value: "Other", label: "Other" },
  { value: "Outline", label: "Outline" },
  { value: "Photograph", label: "Photograph" },
  { value: "Pilot", label: "Pilot" },
  { value: "Pitch", label: "Pitch" },
  { value: "Play", label: "Play" },
  { value: "Poems", label: "Poems" },
  { value: "Proposal", label: "Proposal" },
  { value: "Scene", label: "Scene" },
  { value: "Screenplay", label: "Screenplay" },
  { value: "Script", label: "Script" },
  { value: "Series", label: "Series" },
  { value: "Shorts", label: "Shorts" },
  { value: "Sketch", label: "Sketch" },
  { value: "Spec", label: "Spec" },
  { value: "Story", label: "Story" },
  { value: "Synopsis", label: "Synopsis" },
  { value: "Teleplay", label: "Teleplay" },
  { value: "Trailer", label: "Trailer" },
  { value: "Transplation", label: "Transplation" },
  { value: "Treatment", label: "Treatment" },
];
