import React, { useState } from "react";
const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

export function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [alertData, setAlertData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const forgotPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setAlertData("Please insert email");
      setTimeout(() => setAlertData(false), 3000);
      return;
    }
    setDisabled(true);
    try {
      const response = await fetch(`${serverBaseUrl}/user/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        setSuccess(responseData.message || "Email send successfully");
        setTimeout(() => setSuccess(false), 3000);
        setEmail("");
        localStorage.clear();
        return;
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(false), 3000);
      }
    } catch (error) {
      setAlertData("Email has not been sent due to some error");
      setTimeout(() => setAlertData(false), 3000);
    } finally {
      setDisabled(false);
      return;
    }
  };

  return (
    <>
      {alertData && <div className="error form-error">{alertData}</div>}
      {success && <div className="success form-error">{success}</div>}
      <form onSubmit={forgotPassword} className="signup-form">
        <h3>Title Registry</h3>
        <h4>Forgot Password</h4>
        <div className="input-div">
          <label htmlFor="email">
            Email <span className="required-asterisk">*</span>
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@email.com"
            required
          />
        </div>

        <button type="submit" className="forgot-btn" disabled={disabled}>
          Send Email
        </button>
      </form>
    </>
  );
}
